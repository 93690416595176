import { HttpClient, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ApplicationConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { AUTH_INTERCEPTOR_PROVIDER } from './core/interceptors/auth/auth.interceptor';
import {
  ApiModule as PropertyDataServiceApiModule,
  BASE_PATH as PROPERTY_DATA_SERVICE_API_BASE_PATH,
} from '@booking/booking-property-data-service';
import { ApiModule as MapLocationServiceApiModule } from '@assistant/angular-map-location-service';
import { ApiModule as TourBuilderServiceApiModule } from '@soctrip/tour-builder-service-api';
import { ApiModule as MapLocationServiceApiModuleAssis } from '@assistant/angular-map-location-service';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  ApiModule as RatingServiceApiModule,
  BASE_PATH as RATING_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-rating-service';
import {
  ApiModule as StorageServiceApiModule,
  BASE_PATH as STORAGE_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-storage-service';
import {
  ApiModule as PropertySearchServiceApiModule,
  BASE_PATH as PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
} from '@booking/booking-property-search-service';
import {
  ApiModule as UserAndGuestServiceApiModule,
  BASE_PATH as USER_AND_GUEST_SERVICE_API_BASE_PATH,
} from '@booking/booking-user-data-service';
import {
  ApiModule as FamousSpotServiceApiModule,
  BASE_PATH as FAMOUS_SPOT_SERVICE_API_BASE_PATH,
} from '@booking/booking-famous-spot-service';
import {
  ApiModule as BookingGenericServiceApiModule,
  BASE_PATH as BOOKING_GENERIC_SERVICE_API_BASE_PATH,
} from '@booking/booking-generic-service';
import { environment } from '@env/environment';
import {
  ApiModule as BookmarkServiceApiModule,
  BASE_PATH as BOOKMARK_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-bookmarks-service';
import {
  ApiModule as ShipmentServiceApiModule,
  BASE_PATH as SHIPMENT_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-shipment-service';
import {
  ApiModule as TmTravMapLocationServiceApiModule,
  BASE_PATH as TM_TRAV_MAP_LOCATION_SERVICE_API_BASE_PATH,
} from '@assistant/angular-map-location-service';
import {
  ApiModule as PropertyRoomApiModule,
  BASE_PATH as PROPERTY_ROOM_API_BASE_PATH,
} from '@booking/booking-property-room-service';
import {
  ApiModule as BookingPricingApiModule,
  BASE_PATH as BOOKING_PRICING_API_BASE_PATH,
} from '@booking/booking-pricing-service';
import {
  ApiModule as BookingUserDataApiModule,
  BASE_PATH as BOOKING_USER_DATA_API_BASE_PATH,
} from '@booking/booking-user-data-service';

import {
  ApiModule as ReservationServiceApiModule,
  BASE_PATH as RESERVATION_SERVICE_API_BASE_PATH,
} from '@booking/booking-reservation-service';

import {
  ApiModule as CarrentalServiceApiModule,
  BASE_PATH as CARRENT_SERVICE_API_BASE_PATH,
} from '@car-rental/angular-car-rental-service';
import {
  ApiModule as CarrentalOrderServiceApiModule,
  BASE_PATH as CARRENT_ORDER_SERVICE_API_BASE_PATH,
} from '@car-rental/angular-car-rental-order-service';
import {
  ApiModule as CarrentalOTAServiceApiModule,
  BASE_PATH as CARRENT_OTA_SERVICE_API_BASE_PATH,
} from '@car-rental/angular-car-rental-ota-service';
import {
  ApiModule as CarrentalPromotionServiceApiModule,
  BASE_PATH as CARRENT_PROMOTION_SERVICE_API_BASE_PATH,
} from '@car-rental/angular-car-rental-promotion-service';
import {
  ApiModule as MapServiceApiModule,
  BASE_PATH as MAP_SERVICE_API_BASE_PATH,
} from '@assistant/angular-map-location-service';
import {
  ApiModule as WalletServiceApiModule,
  BASE_PATH as WALLET_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-ecommerce-wallet-service';
import {
  ApiModule as FinanceServiceApiModule,
  BASE_PATH as FINANCE_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-ecommerce-finance-service';
import {
  ApiModule as RegionalServiceApiModule,
  BASE_PATH as REGIONAL_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-regional-service';
import {
  ApiModule as BannerServiceApiModule,
  BASE_PATH as BANNER_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-banner-service';
import { LANGUAGE } from '@shared/enum/language.enum';
import { AppSecurityModule } from '@soctrip-common/app-security';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import {
  ApiModule as CrossSaleModuleNpm,
  Configuration as CrossSaleConfigurationNpm,
} from '@soctrip/angular-cross-sale-service';
import { MessageService } from 'primeng/api';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function ApiConfigFactory(path: string) {
  return {
    // set configuration parameters here
    basePath: `${environment.baseURL}/${path}`,
  };
}

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      /* @angular */
      HttpClientModule,
      ReactiveFormsModule,
      PropertyDataServiceApiModule,
      MapLocationServiceApiModule,
      MapLocationServiceApiModuleAssis,
      TourBuilderServiceApiModule,
      TranslateModule.forRoot({
        defaultLanguage: LANGUAGE.EN,
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler,
        },
      }),
      CrossSaleModuleNpm.forRoot(
        () => new CrossSaleConfigurationNpm(ApiConfigFactory('cross-sale'))
      ),
      ReservationServiceApiModule,
      RatingServiceApiModule,
      PropertySearchServiceApiModule,
      UserAndGuestServiceApiModule,
      FamousSpotServiceApiModule,
      BookingGenericServiceApiModule,
      BookmarkServiceApiModule,
      ShipmentServiceApiModule,
      TmTravMapLocationServiceApiModule,
      StorageServiceApiModule,
      PropertyRoomApiModule,
      BookingPricingApiModule,
      BookingUserDataApiModule,
      CarrentalServiceApiModule,
      CarrentalOrderServiceApiModule,
      CarrentalOTAServiceApiModule,
      MapServiceApiModule,
      WalletServiceApiModule,
      FinanceServiceApiModule,
      CarrentalPromotionServiceApiModule,
      RegionalServiceApiModule,
      BannerServiceApiModule,
      AppSecurityModule.forRoot(),
    ]),
    provideAnimations(),
    provideRouter(APP_ROUTES, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    AUTH_INTERCEPTOR_PROVIDER,
    { provide: MAP_SERVICE_API_BASE_PATH, useValue: environment.MAP_SERVICE_API_BASE_PATH },
    {
      provide: CARRENT_OTA_SERVICE_API_BASE_PATH,
      useValue: environment.CARRENT_OTA_SERVICE_API_BASE_PATH,
    },
    {
      provide: CARRENT_ORDER_SERVICE_API_BASE_PATH,
      useValue: environment.CARRENT_ORDER_SERVICE_API_BASE_PATH,
    },
    { provide: CARRENT_SERVICE_API_BASE_PATH, useValue: environment.CARRENT_SERVICE_API_BASE_PATH },
    {
      provide: RESERVATION_SERVICE_API_BASE_PATH,
      useValue: environment.RESERVATION_SERVICE_API_BASE_PATH,
    },
    {
      provide: PROPERTY_DATA_SERVICE_API_BASE_PATH,
      useValue: environment.PROPERTY_DATA_SERVICE_API_BASE_PATH,
    },
    { provide: RATING_SERVICE_API_BASE_PATH, useValue: environment.RATING_SERVICE_API_BASE_PATH },
    {
      provide: PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
      useValue: environment.PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
    },
    {
      provide: USER_AND_GUEST_SERVICE_API_BASE_PATH,
      useValue: environment.USER_AND_GUEST_SERVICE_API_BASE_PATH,
    },
    {
      provide: FAMOUS_SPOT_SERVICE_API_BASE_PATH,
      useValue: environment.FAMOUS_SPOT_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKING_GENERIC_SERVICE_API_BASE_PATH,
      useValue: environment.BOOKING_GENERIC_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKMARK_SERVICE_API_BASE_PATH,
      useValue: environment.BOOKMARK_SERVICE_API_BASE_PATH,
    },
    {
      provide: SHIPMENT_SERVICE_API_BASE_PATH,
      useValue: environment.SHIPMENT_SERVICE_API_BASE_PATH,
    },
    {
      provide: TM_TRAV_MAP_LOCATION_SERVICE_API_BASE_PATH,
      useValue: environment.TM_TRAV_MAP_LOCATION_SERVICE_API_BASE_PATH,
    },
    { provide: STORAGE_SERVICE_API_BASE_PATH, useValue: environment.STORAGE_SERVICE_API_BASE_PATH },
    { provide: PROPERTY_ROOM_API_BASE_PATH, useValue: environment.PROPERTY_ROOM_API_BASE_PATH },
    { provide: BOOKING_PRICING_API_BASE_PATH, useValue: environment.BOOKING_PRICING_API_BASE_PATH },
    {
      provide: BOOKING_USER_DATA_API_BASE_PATH,
      useValue: environment.BOOKING_USER_DATA_API_BASE_PATH,
    },
    { provide: WALLET_SERVICE_API_BASE_PATH, useValue: environment.WALLET_API_BASE_PATH },
    { provide: FINANCE_SERVICE_API_BASE_PATH, useValue: environment.FINANCE_API_BASE_PATH },
    {
      provide: CARRENT_PROMOTION_SERVICE_API_BASE_PATH,
      useValue: environment.CARRENT_PROMOTION_SERVICE_API_BASE_PATH,
    },
    {
      provide: REGIONAL_SERVICE_API_BASE_PATH,
      useValue: environment.REGIONAL_SERVICE_API_BASE_PATH,
    },
    {
      provide: BANNER_SERVICE_API_BASE_PATH,
      useValue: environment.BANNER_SERVICE_API_BASE_PATH,
    },
    MessageService,
  ],
};
