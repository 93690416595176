import { FirstLoadAuthInfoService } from '@core/services/first-load-auth-info/first-load-auth-info.service';
import { Component, HostListener, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { environment } from '@env/environment';
import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderComponent } from '@layout/header/header.component';
import { BookMarkService } from '@modules/car-booking/services/bookmark/bookmark.service';
import { ModalSubmitComponent } from '@modules/car-ota/components/car-management/components/modal-submit/modal-submit.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { UserService } from 'src/app/core/services/user/user.service';
import { PermissionService } from './core/services/permission/permission.service';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import { Location as LocationAngular } from '@angular/common';
import { distinctUntilChanged, filter, of, Subject, switchMap, takeUntil } from 'rxjs';
import { ProgressBarModule } from 'primeng/progressbar';
import { AgencyRedirectorGuard } from '@core/guards/agency-redirector/agency-redirector.guard';
import { SoctripTranslationService } from './services/soctrip-translation/soctrip-translation.service';
import { GoogleAnalyticsService } from '@core/services/google-analytics.service ';
import { v4 as uuidv4 } from 'uuid';
import { SoctripChatModule } from '@soctrip-common/chat';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    /* primeng */
    ButtonModule,
    ProgressBarModule,
    /* @angular */
    RouterOutlet,
    /* @components */
    FooterComponent,
    HeaderComponent,
    SharedModule,
    ModalSubmitComponent,
    SoctripChatModule,
    ToastModule,
  ],
  styleUrls: ['./app.component.scss'],
  template: `
    <app-confirmation></app-confirmation>
    <app-header />
    <router-outlet></router-outlet>
    <app-popup-cta></app-popup-cta>
    <app-modal-submit></app-modal-submit>
    <p-toast></p-toast>
    <div class="t-auth-loader w-full" *ngIf="this.agencyRedirectorGuard.loader">
      <div class="flex h-[31.25rem] w-full items-center justify-center">
        <p-progressBar
          mode="indeterminate"
          [style]="{ height: '6px', width: '13rem' }"
        ></p-progressBar>
      </div>
    </div>
    <lib-chat-popup class="t-chat-popup"
      *ngIf="environment.SOCCHAT_VISIBLE && isEnableChat && userProfile"
      [baseUrl]="environment.baseURL"
      [userInfo]="userProfile"
      [module]="environment.CHAT_MODULE"
      [isEnableChatBot]="true"
      [isDisableCloseDialogFromOutside]="true"
    ></lib-chat-popup>
    <app-footer />
  `,
})
export class AppComponent {
  //TODO: Replace with actual code
  loginForm = { password: '123456a@A', username: 'testaccount' };
  userRole: string[] = [];
  socialAppSafeUrl: SafeResourceUrl;
  public sanitizer: DomSanitizer;
  snowfalls: any = [];
  approvalPage: boolean = false;
  appConnect: string = '';
  socialAppConnectUrl: string = '';
  unAuthLang = localStorage.getItem(environment.UN_AUTH_LANG) as string;
  userProfile: any = '';
  isEnableChat = false;
  destroy$ = new Subject<void>();
  routerEvent$ = inject(Router).events.pipe(
    filter((event) => event instanceof NavigationEnd),
    distinctUntilChanged(),
    takeUntil(this.destroy$)
  );
  environment = environment;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private translateService: TranslateService,
    private bookMarkService: BookMarkService,
    private primeNGConfig: PrimeNGConfig,
    private location: LocationAngular,
    private firstLoadAuthInfoService: FirstLoadAuthInfoService,
    public agencyRedirectorGuard: AgencyRedirectorGuard,
    private soctripTranslationService: SoctripTranslationService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.googleAnalyticsService.initialize();
    let language = environment.support_languages[0];
    this.translateService.addLangs(environment.support_languages);
    const browserLang = this.translateService.getBrowserLang() || language;
    const selectedLang = localStorage.getItem('lang') || '';
    if (selectedLang && environment.support_languages.includes(selectedLang)) {
      language = selectedLang;
    } else if (!selectedLang && environment.support_languages.includes(browserLang)) {
      language = browserLang;
    }
    localStorage.setItem('lang', language);
    const selectedCurrency = localStorage.getItem('currency') || '';
    if (!selectedCurrency || !environment.support_currency.includes(selectedCurrency)) {
      localStorage.setItem('currency', environment.support_currency[0]);
    }
    this.translateService.setDefaultLang(language);

    if (this.location.path().includes('approval=true')) {
      this.approvalPage = true;
      this.appConnect = environment.APPROVAL_URL;
      this.socialAppConnectUrl = environment.APPROVAL_URL + '/app-connect';
    } else {
      this.appConnect = environment.SOCIAL_APP_URL;
      this.socialAppConnectUrl = environment.SOCIAL_APP_URL + '/app-connect';
    }
  }

  ngOnInit(): void {
    this.generateDeviceId();
    this.bookMarkService.getBloomFilter();
    this.connectPMServer();
    this.translatePrimeng();
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    // this.translateService.stream('PRIMENG').subscribe((data) => {
    //   this.primeNGConfig.setTranslation(data);
    // });
    this.routerEvent$.subscribe((event: any) => {
      this.isEnableChat = event['url']?.includes('agency') ? false : true;
      this.userProfile = localStorage.getItem(environment.USER_PROFILE)
        ? JSON.parse(localStorage.getItem(environment.USER_PROFILE) || '')
        : undefined;
    });
  }

  generateDeviceId() {
    let deviceId = localStorage.getItem(environment.DEVICE_ID);
    if (!deviceId) {
      deviceId = uuidv4();
      if (deviceId) localStorage.setItem(environment.DEVICE_ID, deviceId);
    }
  }

  translatePrimeng() {
    this.translateService.onLangChange
      .pipe(
        switchMap((event: LangChangeEvent) => {
          const res = this.translateService.getParsedResult(event.translations, 'PRIMENG');
          return of(res);
        })
      )
      .subscribe((res: any) => {
        const translations = this.applyPrimeNGTranslations(res);
        this.primeNGConfig.setTranslation(translations);
      });
  }

  private applyPrimeNGTranslations(translations: any): any {
    switch (typeof translations) {
      case 'function':
        return translations();
      case 'object':
        if (Array.isArray(translations)) {
          return translations.map((t) => this.applyPrimeNGTranslations(t));
        }

        for (const key in translations) {
          if (key) {
            translations[key] = this.applyPrimeNGTranslations(translations[key]);
          }
        }
        return translations;
      default:
        return translations;
    }
  }

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.socialAppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'connect' }, '*');
    };
    ifr.onload = connect;
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    if (
      ['authorized', 'unauthorized'].includes(event.data.type) &&
      event.origin === this.appConnect
    ) {
      this.socialAppConnectUrl = '';
      const currenProfile = localStorage.getItem(environment.USER_PROFILE);
      const profile = JSON.parse(event.data.profile ?? '{}');
      this.userProfile = event.data.profile ? JSON.parse(event.data.profile) : undefined;
      if (event.data.type === 'unauthorized' || !event.data || !event.data.token) {
        this.soctripTranslationService.setLanguage(event.data?.language as string);
        localStorage.setItem(environment.UN_AUTH_LANG, event.data?.language);
        if (!this.approvalPage) {
          this.tokenLogout();
          if (currenProfile || (this.unAuthLang && this.unAuthLang !== event.data.language)) {
            location.reload();
          }
        }
      } else if (event.data.type === 'authorized') {
        localStorage.removeItem(environment.UN_AUTH_LANG);
        this.tokenStorageService.saveToken(event.data.token.accessToken);
        this.tokenStorageService.saveRefreshToken(event.data.token.accessToken);
        localStorage.setItem(environment.USER_ROLE_KEY, JSON.stringify(['USER']));
        localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: true }));

        // if (!(currenProfile && JSON.parse(currenProfile).id === profile.id)) {
        this.userService.getUserInfoById(profile.id).subscribe((data) => {
          if (data) {
            this.userService.setUserInfo(data);
            localStorage.setItem(environment.USER_PROFILE, JSON.stringify(data));
            this.userService.setLoginStatus(true);
            if (
              !(
                currenProfile &&
                String(JSON.parse(currenProfile).language).toLocaleLowerCase() ===
                  String(data.language).toLocaleLowerCase()
              ) &&
              !this.agencyRedirectorGuard.loader
            ) {
              location.reload();
            }
          } else {
            this.tokenLogout();
          }
        });

        this.firstLoadAuthInfoService.updateFirstLoadAuthInfo();
      }
    }
  }

  @HostListener('window:storage', ['$event'])
  storageEventListener(event: StorageEvent) {
    if (event.key === environment.USER_PROFILE) {
      const newValueStorage = event.newValue;
      const newProfile = newValueStorage ? JSON.parse(newValueStorage) : {};
      this.userService.getUserInfor().subscribe((user) => {
        if (user?.id !== newProfile.id) {
          window.location.reload();
        }
      });
    }
  }

  private tokenLogout() {
    this.userService.setUserInfo(null);
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.USER_PROFILE);
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.USER_ROLE_KEY);
    localStorage.removeItem(environment.USER_UUID);
    localStorage.setItem(environment.AUTH_STATUS, JSON.stringify({ isLoggedIn: false }));
    this.userService.setLoginStatus(false);
  }

  //TODO: Replace with actual code
  getUserInfo() {
    this.authService.getUserInfo().subscribe();
    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
