import { Component, HostListener } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '@env/environment';
import { Media } from '@modules/car-booking/models/customer-review.model';
import { VideoPathPipe } from '@shared/pipes/video-path.pipe';
export enum KEY_CODE {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
}

@Component({
  selector: 'app-show-image-modal',
  templateUrl: './show-image-modal.component.html',
  styleUrls: ['./show-image-modal.component.scss'],
  providers: [VideoPathPipe],
})
export class ShowImageModalComponent {
  selectedImage: any;
  hotel_id: any;
  image_urls: any[] = [];
  all_image_urls: any[] = [];
  _activeIndex: number = 0;
  pageIndex = 1;
  pageSize = 20;
  isCheckImageId: boolean;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

  get activeIndex(): number {
    return this._activeIndex;
  }

  set activeIndex(newValue) {
    if (this.image_urls && 0 <= newValue && newValue <= this.image_urls.length - 1) {
      this._activeIndex = newValue;
    }
  }

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 12,
    },
    {
      breakpoint: '768px',
      numVisible: 7,
    },
    {
      breakpoint: '370px',
      numVisible: 1,
    },
  ];
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === KEY_CODE.ArrowLeft) {
      this.prev();
    } else if (event.key === KEY_CODE.ArrowRight) {
      this.next();
    }
  }

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private videoPipe: VideoPathPipe
  ) {
    if (this.config.data) {
      this._activeIndex = this.config.data.image_id;
      this.all_image_urls = this.config.data.image_urls;
      this.isCheckImageId = this.config.data.isCheckImageId;
    }
    this.scrollImage();
  }
  ngOnInit() {
    const page = Math.ceil(this._activeIndex / this.pageSize);
    for (let i = 1; i <= page; i++) {
      this.getImages(this.pageIndex, this.pageSize);
      this.pageIndex++;
    }
    this.loadMorePictures();
  }
  onclose() {
    this.ref.close();
  }
  next() {
    this.activeIndex++;
    this.scrollImage();
    this.loadMorePictures();
  }
  prev() {
    this.activeIndex--;
    this.scrollImage();
  }
  onMouseWheelImage(event: WheelEvent) {
    const container = document.querySelector('.image-item');
    if (container) {
      const delta = event.deltaY || event.detail;
      if (delta > 0) this.next();
      else if (delta < 0) this.prev();
      event.preventDefault();
    }
  }
  scrollImage() {
    const tixPos = document.querySelector(`#image-` + this.activeIndex);
    let vid: any = document.getElementById('video-preview');
    if (vid) {
      vid.src = `${this.videoPipe.transform(this.image_urls[this.activeIndex])}`;
    }
    if (tixPos) {
      tixPos.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }
  handleImageScroll(index: number): void {
    this.activeIndex = index;
    this.scrollImage();
    this.loadMorePictures();
  }
  getImages(pageNum: number, pageSize: number) {
    let value = this.all_image_urls.slice((pageNum - 1) * pageSize, pageNum * pageSize);
    this.image_urls = [...this.image_urls, ...value];
  }
  loadMorePictures() {
    if (
      this.activeIndex >= this.image_urls.length - 15 &&
      this.image_urls.length < this.all_image_urls.length
    ) {
      this.getImages(this.pageIndex, this.pageSize);
      this.pageIndex += 1;
    }
  }
  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-hotel.svg';
  }
  isImageFile(media: Media) {
    try {
      if (media.type.includes('image')) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
}
